<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Conciliaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tif</li>
                  <li class="breadcrumb-item active">Procesos</li>
                  <li class="breadcrumb-item active">Conciliaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-10"></div>
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn bg-indigo"
                        data-toggle="modal"
                        data-target="#modal-form-cargue-masivo"
                        v-if="$store.getters.can('tif.conciliaciones.cargueMasivo')"
                      >
                        <i class="fas fa-upload"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-info"
                        @click="getIndex(page)"
                      >
                        <i class="fas fa-sync-alt"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_tifconciliaciones_export"
                        v-if="$store.getters.can('tif.conciliaciones.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <div class="row">
                  <div class="col-md-12">
                    <table
                      class="
                        table table-bordered table-striped table-hover table-sm
                      "
                      style="font-size: 82%"
                    >
                      <thead class="thead">
                        <tr>
                          <th>Recalcular</th>
                          <th>
                            # Turno
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              v-model="filtros.id"
                              @input="getIndex()"
                            />
                          </th>
                          <th>
                            Estado
                            <select
                              v-model="filtros.estado"
                              class="form-control form-control-sm"
                              placeholder="Estado"
                              label="descripcion"
                              @change="getIndex()"
                            >
                              <option value="">Seleccione...</option>
                              <option
                                v-for="estado in listasForms.estados"
                                :key="estado.numeracion"
                                :value="estado.numeracion"
                              >
                                {{ estado.descripcion }}
                              </option>
                            </select>
                          </th>
                          <th>
                            Vehículo
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              v-model="filtros.vehiculo_id"
                              @change="getIndex()"
                            />
                          </th>
                          <th>
                            Remolque
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              v-model="filtros.remolque_id"
                              @input="getIndex()"
                            />
                          </th>
                          <th>
                            Conductor
                            <v-select
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              v-model="conductor"
                              placeholder="Nombre"
                              label="nombre"
                              :options="listasForms.conductores"
                              @input="seleccionarConductor()"
                            ></v-select>
                          </th>
                          <th>
                            Tipo Operación
                            <input
                              type="text"
                              v-model="filtros.operacion"
                              placeholder="Tipo Operación"
                              class="form-control form-control-sm"
                              @input="getIndex()"
                            />
                          </th>
                          <th>
                            Tipo Tarifa
                            <select
                              class="form-control form-control-sm"
                              v-model="filtros.tipo_tarifa"
                              @change="getIndex()"
                            >
                              <option value="">Seleccione...</option>
                              <option
                                v-for="tarifa in listasForms.tipo_tarifas"
                                :key="tarifa.numeracion"
                                :value="tarifa.numeracion"
                              >
                                {{ tarifa.descripcion }}
                              </option>
                            </select>
                          </th>
                          <th>
                            Bloque
                            <v-select
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              v-model="bloque"
                              placeholder="Bloques"
                              label="nombre"
                              :options="listasForms.bloques"
                              class="form-control form-control-sm p-0"
                              @input="getSelectBloques()"
                            ></v-select>
                          </th>
                          <th>
                            Transportadora
                            <v-select
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              v-model="empresa"
                              placeholder="Razon social"
                              label="razon_social"
                              :options="listasForms.empresas"
                              @input="seleccionarTransportadora()"
                            ></v-select>
                          </th>
                          <th>
                            Producto
                            <v-select
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              v-model="producto"
                              placeholder="Nombre"
                              label="nombre"
                              :options="listasForms.productos"
                              @input="seleccionarProducto()"
                            ></v-select>
                          </th>
                          <th>
                            Tarifa
                            <select
                              class="form-control form-control-sm"
                              v-model="filtros.tarifa"
                              @change="getIndex()"
                            >
                              <option value="1">Asignada</option>
                              <option value="2">Pendiente</option>
                            </select>
                          </th>
                          <th>
                            Fecha Inicio
                            <input
                              type="date"
                              class="form-control form-control-sm p-0"
                              v-model="filtros.fecha_inicio"
                              @input="validaFechas()"
                              style="font-size: 10px"
                            />
                          </th>
                          <th>
                            Fecha Fin
                            <input
                              type="date"
                              class="form-control form-control-sm p-0"
                              v-model="filtros.fecha_fin"
                              @input="validaFechas()"
                              style="font-size: 10px"
                            />
                          </th>
                          <th>Tramas</th>
                          <th>Novedad</th>
                          <th>Kilometros</th>
                          <th>Odometro</th>
                          <th>Firmar</th>
                        </tr>
                      </thead>
                      <tbody v-for="item in turnos.data" :key="item.id">
                        <tr>
                          <td>
                            <button
                              type="button"
                              class="btn btn-sm alert-default-primary py-0"
                              data-toggle="modal"
                              data-target="#modal-form-recalculo"
                              style="cursor: pointer"
                              @click="llenarModalRecalculo(item.id)"
                              v-if="
                                $store.getters.can(
                                  'tif.conciliaciones.recalcularTurno'
                                )
                              "
                            >
                              <i class="fas fa-spinner"></i>
                            </button>
                          </td>
                          <td>
                            <button
                              type="button"
                              class="btn btn-sm alert-default-success py-0"
                              data-toggle="modal"
                              data-target="#modal-form-detTurno"
                              @click="llenarModalDetTurno(item.id)"
                            >
                              {{ item.id }}
                            </button>
                          </td>
                          <td v-if="item.tif_conciliacion">
                            <span
                              class="badge"
                              :class="
                                item.tif_conciliacion.estado == 1
                                  ? 'badge-info'
                                  : item.tif_conciliacion.estado == 2
                                  ? 'badge-success'
                                  : 'badge-danger'
                              "
                            >
                              {{ item.tif_conciliacion.estadoConciliacion }}
                            </span>
                          </td>
                          <td v-else>
                            <span class="badge badge-danger">PENDIENTE</span>
                          </td>
                          <td>
                            {{ item.vehiculo.placa }}
                          </td>
                          <td v-if="item.remolque != null">
                            {{ item.remolque.placa }}
                          </td>
                          <td v-else>
                            <span class="badge badge-danger">
                              Sin Asignar
                            </span>
                          </td>
                          <td>
                            {{ item.conductor.nombres }}
                            {{ item.conductor.apellidos }}
                            <span class="badge badge-info">
                              {{ item.conductor.numero_documento }}
                            </span>
                          </td>
                          <td>
                            {{ item.operacion.Noperacion }}
                          </td>
                          <td>{{ item.tipoTarifa }}</td>
                          <td>
                            {{ item.bloque.nombre }}
                          </td>
                          <td>
                            {{ item.empresa.razon_social }}
                          </td>
                          <td>
                            {{ item.producto.nombre }}
                          </td>
                          <td>
                            <span
                              class="badge"
                              :class="
                                item.tif_det_tarifa_tiempo_id == null
                                  ? 'badge-danger'
                                  : 'badge-primary'
                              "
                            >
                              {{
                                item.tif_det_tarifa_tiempo_id == null
                                  ? "Pendiente"
                                  : "Asignada"
                              }}
                            </span>
                          </td>
                          <td style="font-size: 11px; width: 7%">
                            {{ item.fecha_ini }}
                          </td>
                          <td style="font-size: 11px; width: 7%">
                            {{ item.fecha_fin }}
                          </td>
                          <td class="text-center">
                            <div
                              class="btn-group"
                              :class="
                                turnos.data.length == 1 ? 'dropop' : 'dropright'
                              "
                              style="position: static; z-index: auth"
                            >
                              <button
                                type="button"
                                class="btn btn-md"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i class="fas fa-route"></i>
                              </button>
                              <div class="dropdown-menu">
                                <a
                                  class="dropdown-item"
                                  data-toggle="modal"
                                  data-target="#modal-form-subir-tramas"
                                  style="cursor: pointer"
                                  @click="llenarModalTramas(item)"
                                  v-if="
                                    ($store.getters.can(
                                      'tif.tramasPendientes.edit'
                                    ) &&
                                      item.tif_tramas_pendientes.length == 0) ||
                                      item.tif_tramas_pendientes[0].estado == 3
                                  "
                                >
                                  <i class="fas fa-user-edit"></i>
                                  Petición Tramas
                                </a>
                                <a
                                  type="button"
                                  class="dropdown-item"
                                  style="cursor: pointer"
                                  @click="anulacionTramas(item)"
                                  v-if="
                                    $store.getters.can(
                                      'tif.tramasPendientes.anulacionTramas'
                                    ) &&
                                      item.tif_tramas_pendientes.length > 0 &&
                                      item.tif_tramas_pendientes[0].estado == 1
                                  "
                                >
                                  <i class="fas fa-user-slash"></i>
                                  Anulación Petición Tramas
                                </a>
                                <a
                                  v-if="
                                    item.tif_tramas_pendientes.length > 0 &&
                                      item.tif_tramas_pendientes[0].estado == 2
                                  "
                                  ><h5>
                                    <span class="badge badge-success m-2"
                                      ><i class="fas fa-check"></i> Tramas
                                      Cargadas.</span
                                    >
                                  </h5>
                                </a>
                              </div>
                            </div>
                          </td>
                          <td style="width: 50px" class="text-center">
                            <div class="btn-group float-center">
                              <button
                                type="button"
                                class="btn btn-sm btn-default py-0"
                                data-toggle="modal"
                                data-target="#modal-form-tifNovedades"
                                @click="llenarModalNovedades(item)"
                              >
                                {{ item.tif_novedades.length }}
                              </button>
                            </div>
                          </td>
                          <td style="width: 50px" class="text-center">
                            <div class="btn-group float-center">
                              <button
                                type="button"
                                :class="
                                  !item.tif_km
                                    ? 'btn btn-sm alert-default-danger'
                                    : 'btn btn-sm alert-default-info'
                                "
                                v-show="item.tipo_tarifa == 2"
                                data-toggle="modal"
                                data-target="#modal-form-km"
                                @click="llenar_modal_km(item)"
                              >
                                <i class="fas fa-road"></i>
                              </button>
                              <div>
                                <span
                                  class="badge badge-success"
                                  v-if="item.tif_km"
                                >
                                  {{ item.tif_km.km_final }}</span
                                >
                              </div>
                            </div>
                          </td>
                          <td style="width: 50px" class="text-center">
                            <div class="btn-group float-center">
                              <button
                                type="button"
                                :class="
                                  !item.tif_odometro
                                    ? 'btn btn-sm alert-default-danger'
                                    : 'btn btn-sm alert-default-info'
                                "
                                data-toggle="modal"
                                data-target="#modal-form-odometro"
                                @click="llenar_modal_odometro(item)"
                              >
                                <i class="fas fa-tachometer-alt"></i>
                              </button>
                            </div>
                          </td>
                          <td style="width: 50px" class="text-left">
                            <div class="btn-group float-left">
                              <button
                                type="button"
                                class="btn btn-sm alert-default-primary"
                                data-toggle="modal"
                                data-target="#modal-form-firmaConciliacion"
                                v-if="item.tif_det_tarifa_tiempo_id != null"
                                @click="llenar_modal_firma(item)"
                              >
                                <i class="fas fa-signature"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="19">
                            <!--- Div Progress Tarifa 1 Pozos Satelites --->
                            <div
                              class="progress"
                              style="height: 15px"
                              v-if="
                                item.tipo_tarifa == 1 &&
                                  (item.tif_puntos_turno.length > 0 ||
                                    item.tif_novedades.length > 0) &&
                                  item.tif_recorridos.length > 0
                              "
                            >
                              <div
                                class="
                                  progress-bar progress-bar-striped
                                  bg-success
                                  progress-bar-animated
                                "
                                role="progressbar"
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                :style="
                                  'width:' +
                                    (item['tiempo_op_ps'] * 60 * 100) /
                                      (item['horas_turno'] * 60) +
                                    '%;'
                                "
                              >
                                <b>{{ item["tiempo_op_ps"] * 60 }}</b>
                              </div>

                              <div
                                class="
                                  progress-bar progress-bar-striped
                                  bg-warning
                                  progress-bar-animated
                                "
                                role="progressbar"
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                :style="
                                  'width:' +
                                    ((item['horas_turno'] * 60 -
                                      item['tiempo_op_ps'] * 60 -
                                      item['tiempoIndisponible']) *
                                      100) /
                                      (item['horas_turno'] * 60) +
                                    '%;'
                                "
                              >
                                <b>{{
                                  item["horas_turno"] * 60 -
                                    item["tiempo_op_ps"] * 60 -
                                    item["tiempoIndisponible"]
                                }}</b>
                              </div>

                              <div
                                class="
                                  progress-bar progress-bar-striped
                                  bg-danger
                                  progress-bar-animated
                                "
                                role="progressbar"
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                :style="
                                  'width:' +
                                    (item['tiempoIndisponible'] * 100) /
                                      (item['horas_turno'] * 60) +
                                    '%;'
                                "
                              >
                                <b>{{ item["tiempoIndisponible"] }}</b>
                              </div>
                            </div>
                            <!--- Div Progress Tarifa 2 Fijo + Variable --->
                            <div
                              class="progress"
                              style="height: 15px"
                              v-else-if="
                                item.tipo_tarifa == 2 &&
                                  (item.tif_puntos_turno.length > 0 ||
                                    item.tif_novedades.length > 0) &&
                                  item.tif_recorridos.length > 0
                              "
                            >
                              <div
                                class="
                                  progress-bar progress-bar-striped
                                  bg-success
                                  progress-bar-animated
                                "
                                role="progressbar"
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                :style="
                                  'width:' +
                                    ((item['horas_turno'] * 60 -
                                      item['tiempoIndisponible']) *
                                      100) /
                                      (item['horas_turno'] * 60) +
                                    '%;'
                                "
                              >
                                <b>{{
                                  item["horas_turno"] * 60 -
                                    item["tiempoIndisponible"]
                                }}</b>
                              </div>
                              <div
                                class="
                                  progress-bar progress-bar-striped
                                  bg-danger
                                  progress-bar-animated
                                "
                                role="progressbar"
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                :style="
                                  'width:' +
                                    (item['tiempoIndisponible'] * 100) /
                                      (item['horas_turno'] * 60) +
                                    '%;'
                                "
                              >
                                <b>{{ item["tiempoIndisponible"] }}</b>
                              </div>
                            </div>
                            <!--- Div Progress Tarifa 3 Tiempos --->
                            <div
                              class="progress"
                              style="height: 15px"
                              v-else-if="
                                item.tipo_tarifa == 3 &&
                                  (item.tif_puntos_turno.length > 0 ||
                                    item.tif_novedades.length > 0) &&
                                  item.tif_recorridos.length > 0
                              "
                            >
                              <div
                                class="
                                  progress-bar progress-bar-striped
                                  bg-success
                                  progress-bar-animated
                                "
                                role="progressbar"
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                :style="
                                  'width:' +
                                    ((item['horas_turno'] * 60 -
                                      item['tiempoDisponible'] -
                                      item['tiempoIndisponible']) *
                                      100) /
                                      (item['horas_turno'] * 60) +
                                    '%;'
                                "
                              >
                                <b>{{
                                  item["horas_turno"] * 60 -
                                    item["tiempoDisponible"] -
                                    item["tiempoIndisponible"]
                                }}</b>
                              </div>

                              <div
                                class="
                                  progress-bar progress-bar-striped
                                  bg-warning
                                  progress-bar-animated
                                "
                                role="progressbar"
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                :style="
                                  'width:' +
                                    (item['tiempoDisponible'] * 100) /
                                      (item['horas_turno'] * 60) +
                                    '%;'
                                "
                              >
                                <b>{{ item["tiempoDisponible"] }}</b>
                              </div>

                              <div
                                class="
                                  progress-bar progress-bar-striped
                                  bg-danger
                                  progress-bar-animated
                                "
                                role="progressbar"
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                :style="
                                  'width:' +
                                    (item['tiempoIndisponible'] * 100) /
                                      (item['horas_turno'] * 60) +
                                    '%;'
                                "
                              >
                                <b>{{ item["tiempoIndisponible"] }}</b>
                              </div>
                            </div>
                            <div class="progress" style="height: 15px" v-else>
                              <div
                                class="
                                  progress-bar progress-bar-striped
                                  bg-navy
                                  progress-bar-animated
                                "
                                role="progressbar"
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style="width: 100%; color: #fffefe"
                              >
                                <b style="color: #fffefe">N / A</b>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="float-left" v-if="turnos.total">
              <p>
                Mostrando del <b>{{ turnos.from }}</b> al
                <b>{{ turnos.to }}</b> de un total:
                <b>{{ turnos.total }}</b> Registros
              </p>
            </div>
            <div class="float-left" v-else>
              <p>
                <span class="badge badge-danger">
                  No hay registros para mostrar
                </span>
              </p>
            </div>
            <pagination
              :data="turnos"
              @pagination-change-page="getIndex"
              :limit="5"
              align="right"
            ></pagination>
          </div>
        </section>
        <TifTurnoDet ref="TifTurnoDet" />
        <TifNovedades ref="TifNovedades" />
        <TifFirmaConciliacion ref="TifFirmaConciliacion" />
        <TifTurnoOdo ref="TifTurnoOdo" />
        <TifKilometrosTurno ref="TifKilometrosTurno" />
        <TifConciliacionesExport ref="TifConciliacionesExport" />
        <TifConciliacionesCargueMasivo ref="TifConciliacionesCargueMasivo" />
        <TifConciliacionesRecalculoTurnos
          ref="TifConciliacionesRecalculoTurnos"
        />
        <TifVentanaGeneralOperacionesCambios
          ref="TifVentanaGeneralOperacionesCambios"
        />
      </div>
    </div>
  </div>
</template>
<script>
import pagination from "laravel-vue-pagination";
import vSelect from "vue-select";
import TifTurnoDet from "../turnos/TifTurnoDet";
import TifFirmaConciliacion from "../conciliaciones/TifFirmaConciliacion";
import TifTurnoOdo from "../conciliaciones/TifTurnoOdo";
import TifKilometrosTurno from "../conciliaciones/TifKilometrosTurno";
import TifNovedades from "../conciliaciones/TifNovedades";
import TifConciliacionesExport from "./TifConciliacionesExport";
import TifConciliacionesCargueMasivo from "./TifConciliacionesCargueMasivo";
import TifConciliacionesRecalculoTurnos from "./TifConciliacionesRecalculoTurnos";
import TifVentanaGeneralOperacionesCambios from "../ventanasGeneralOperaciones/TifVentanaGeneralOperacionesCambios";
import axios from "axios";
import Loading from "../../../../components/Loading";
export default {
  name: "TifConciliacionesIndex",
  components: {
    Loading,
    pagination,
    TifTurnoDet,
    TifNovedades,
    vSelect,
    TifFirmaConciliacion,
    TifTurnoOdo,
    TifConciliacionesExport,
    TifConciliacionesCargueMasivo,
    TifConciliacionesRecalculoTurnos,
    TifKilometrosTurno,
    TifVentanaGeneralOperacionesCambios,
  },
  data() {
    return {
      cargando: false,
      page: 1,
      turnos: {},
      filtros: {},
      bloque: null,
      operacion: null,
      conductor: null,
      conductor2: null,
      vehiculo: null,
      remolque: null,
      empresa: null,
      ruta: null,
      producto: null,
      listasForms: {
        estados: [],
        conductores: [],
        productos: [],
        rutas: [],
        cabezotes: [],
        remolques: [],
        empresas: [],
        tipo_tarifas: [],
        campo: [],
      },
    };
  },

  methods: {
    async getIndex(page = 1) {
      this.page = page;
      this.cargando = true;
      await axios
        .get("/api/tif/conciliaciones/index?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.turnos = response.data;
          this.cargando = false;
        });
    },

    validaFechas() {
      const fecha_menor = new Date(this.filtros.fecha_inicio);
      const fecha_mayor = new Date(this.filtros.fecha_fin);
      // Se valida que la fecha inicial sea menor que la fecha final
      if (fecha_menor > fecha_mayor) {
        this.filtros.fecha_fin = null;
        this.$swal({
          icon: "error",
          title: `La fecha inicio no puede ser mayor a la fecha fin...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        this.getIndex();
      }
    },

    llenar_modal_firma(item) {
      this.$refs.TifFirmaConciliacion.llenar_modal_firma(item);
    },

    llenar_modal_odometro(item) {
      this.$refs.TifTurnoOdo.llenar_modal_odometro(item);
    },

    llenar_modal_km(item) {
      this.$refs.TifKilometrosTurno.llenar_modal_km(item);
    },

    getTipoTarifa() {
      axios.get("/api/lista/96").then((response) => {
        this.listasForms.tipo_tarifas = response.data;
      });
    },

    getBloques() {
      axios.get("/api/admin/bloques/lista").then((response) => {
        this.listasForms.bloques = response.data;
      });
    },

    buscarCabezotes() {
      let me = this;
      var url = "api/admin/vehiculos/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.cabezotes = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarRemolques() {
      let me = this;
      var url = "api/admin/remolques/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.remolques = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarRutas() {
      let me = this;
      var url = "api/admin/rutas/lista";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.rutas = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarProductos() {
      let me = this;
      axios
        .get("api/tif/conciliaciones/productos", {})
        .then(function(response) {
          me.listasForms.productos = response.data;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarConductores() {
      let me = this;
      var url = "api/admin/conductores/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.conductores = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarConductores2() {
      let me = this;
      var url = "api/admin/conductores/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.conductores2 = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarTransportadora() {
      let me = this;
      var url = "api/admin/empresas/lista?estado=1";
      axios
        .get(url, {
          params: {
            linea_negocio_id: [3],
          },
        })
        .then(function(response) {
          me.listasForms.empresas = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    seleccionarVehiculo() {
      if (this.vehiculo != null) {
        this.filtros.vehiculo_id = this.vehiculo.id;
      } else {
        this.filtros.vehiculo_id = null;
      }
    },

    seleccionarRemolque() {
      if (this.remolque != null) {
        this.filtros.remolque_id = this.remolque.id;
      } else {
        this.filtros.remolque_id = null;
      }
    },

    seleccionarTransportadora() {
      if (this.empresa != null) {
        this.filtros.empresa_id = this.empresa.id;
      } else {
        this.filtros.empresa_id = null;
      }
      this.getIndex();
    },

    seleccionarConductor() {
      if (this.conductor != null) {
        this.filtros.conductor_id = this.conductor.id;
      } else {
        this.filtros.conductor_id = null;
      }

      this.getIndex();
    },

    seleccionarConductor2() {
      if (this.conductor2 != null) {
        this.filtros.conductor_id_2 = this.conductor2.id;
      } else {
        this.filtros.conductor_id_2 = null;
      }
      this.getIndex();
    },

    seleccionarRuta() {
      if (this.ruta != null) {
        this.filtros.ruta_id = this.ruta.id;
      } else {
        this.filtros.ruta_id = null;
      }
    },

    seleccionarProducto() {
      if (this.producto != null) {
        this.filtros.producto_id = this.producto.id;
      } else {
        this.filtros.producto_id = null;
      }
      this.getIndex();
    },

    getSelectBloques() {
      if (this.bloque != null) {
        this.filtros.bloque_id = this.bloque.id;
      } else {
        this.filtros.bloque_id = null;
      }
      this.getIndex();
    },

    direccionar(item = null) {
      let accion = "Crear";
      if (item) {
        accion = "Editar";
      }
      return this.$router.push({
        name: "/Tif/TurnosForm",
        params: {
          accion,
          item,
        },
      });
    },

    llenarModalDetTurno(turno) {
      this.$refs.TifTurnoDet.llenar_modal_detTurno(turno);
    },

    async llenarModalNovedades(turno) {
      await this.$refs.TifNovedades.llenar_modal_novedades(turno);
    },

    getEstados() {
      axios.get("/api/lista/115").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    llenarModalRecalculo(idTurno) {
      if (this.$store.getters.can("tif.conciliaciones.recalcularTurno")) {
        this.$refs.TifConciliacionesRecalculoTurnos.llenar_modal(idTurno);
      }
    },

    llenarModalTramas(turno) {
      this.$refs.TifVentanaGeneralOperacionesCambios.llenar_modal_tramas(turno);
    },

    anulacionTramas(turno) {
      this.$swal({
        title: "Esta seguro de anular la petición del turno: " + turno.id + "?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Cancelar!",
      })
        .then((result) => {
          if (result.value) {
            axios({
              method: "PUT",
              url: "/api/tif/tramasPendientes/anulacionTramas",
              data: turno,
            }).then(() => {
              this.cargando = true;
              this.$swal({
                icon: "success",
                title: "Se cancelo la petición exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.getIndex();
            });
          }
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error inesperado " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  mounted() {
    this.getIndex();
    this.getEstados();
    this.getTipoTarifa();
    this.getBloques();
    this.buscarCabezotes();
    this.buscarRemolques();
    this.buscarTransportadora();
    this.buscarConductores();
    this.buscarConductores2();
    this.buscarProductos();
    this.buscarRutas();
  },
};


</script>